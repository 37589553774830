import React, { useState, useEffect } from 'react';
import { Validators } from 'react-reactive-form';
import { Box, Container, Stack, Typography, Grid, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FieldGroup } from 'react-reactive-form';
import BottomImage from '../../../assets/icons/side-bottom.svg';
import LogoImage from '../../../assets/icons/logo.svg';
import PrimaryButton from '../../../core/buttons/primary-button';
import ShadowImage from '../../../assets/icons/login_shadow.svg';
import TopImage from '../../../assets/icons/side-top.svg';
import Avatar from '@mui/material/Avatar';
import ChatFloatingIcon from '../../../components/chat-floating-icon';
import { FieldControl } from 'react-reactive-form';
import { RadioInput } from '../../../core/forms/inputs/RadioInput';
import { InputPhone } from '../../../core/forms/inputs/InputPhone';
import EmailInputIcon from '../../../core/forms/inputs/EmailInputIcon';
import PasswordInputIcon from '../../../core/forms/inputs/PasswordInputIcon';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { PatientLoginFormSmsOtp, PatientLoginSendSmsOtp, PatientLoginSendEmailOtp, PatientLoginFormPassword, PatientLoginFormPasswordHandler, PatientLoginFormEmailOtp } from '../../../services/auth/login.service';
import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api';
import { SEND_SMS_OTP } from '../../../constants/Endpoints';
import { toast } from 'react-toastify';
import { profileActions } from '../../../store/Actions';
import { User } from '../../../models/User';
import { InputText } from '../../../core/forms/inputs/InputText';
import { BootstrapDialog } from '../../../core/tables/tableStyles';
import { LoginCustomerSupport } from '../../../components/popup/LoginCustomerSupport';
import { PatientSignUpOneForm, PatientSignUpTwoForm } from '../../../services/auth/register.service';

const { REACT_APP_ENV_NAME } = process.env;
const isDev = REACT_APP_ENV_NAME !== 'production';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedForm, setSelectedForm] = useState<string>('password');
  const [openCustomer, setOpenCustomer] = useState<boolean>(false);

  const handleSendSmsOtp = async () => {
    await PatientLoginSendSmsOtp(navigate);
  };

  const handleSendEmailOtp = async () => {
    await PatientLoginSendEmailOtp(navigate);
  };

  const handlePasswordLogin = async () => {
    const data = await PatientLoginFormPasswordHandler(navigate);
    if (data === 450) {
      setOpenCustomer(true);
    } else {
      if (data) {
        dispatch(profileActions.setUserData({ user: data as User }));
      }
    }
  };

  const navigateToSignupPage = () => {
    localStorage.clear();
    PatientSignUpOneForm.reset();
    PatientSignUpTwoForm.reset();
    navigate('/signup-step-one');
  };

  const handleNavigateToForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleCustomerSupportLoginClose = () => {
    setOpenCustomer(false);
  };

  return (
    <>
      {openCustomer && (
        <BootstrapDialog onClose={handleCustomerSupportLoginClose} open={openCustomer} PaperProps={{ style: { minHeight: '30%', maxHeight: 'auto', minWidth: '30%', maxWidth: 650 } }}>
          <LoginCustomerSupport handleClose={handleCustomerSupportLoginClose} />
        </BootstrapDialog>
      )}
      <Box className="page login-page d-vh-center">
        <Container maxWidth={false} className="login_outer">
          <Stack direction="column" className="login-card">
            <Box className="logo-box d-vh-center">
              <img src={LogoImage} alt="logo" width={320} />
            </Box>
            <Box className="header-text-box">
              <h1>
                America's #1 <br></br>
                Ophthalmic Pharmacy<sup>TM</sup>
              </h1>
              <Typography variant="h6">Licensed in 50 U.S. States</Typography>
            </Box>
            <Stack direction="column" className="login-form-box">
              <Stack>
                <Typography className="title">Patient Portal</Typography>
              </Stack>
              <Stack>
                <Typography className="subtitle">Access your account to manage your prescriptions</Typography>
              </Stack>
              <Box className="register-text-container" alignItems="center">
                <Typography className="register-text">
                  Don’t have an account?
                  <span className="register-here-text" onClick={navigateToSignupPage}>
                    <Link to="/signup-step-one" style={{ textDecoration: 'none' }}>
                      {' '}
                      Register Here
                    </Link>
                  </span>
                </Typography>
              </Box>

              {/* {isDev && (
                <Grid container direction="row" justifyContent="space-between" mt={2} mb={0} sm={12} lg={12} md={12} xl={12}>
                  <Grid item className="radio_grid" id="shipto_Facility_box">
                    <FormControlLabel value="password" control={<Radio onChange={() => setSelectedForm('password')} />} label="Email and Password" checked={selectedForm === 'password'} />
                  </Grid>
                  <Grid item className="radio_grid" id="shipto_Facility_box">
                    <FormControlLabel value="sms_otp" control={<Radio onChange={() => setSelectedForm('sms_otp')} />} label="SMS Code" checked={selectedForm === 'sms_otp'} />
                  </Grid>
                  <Grid item className="radio_grid" id="shipto_Facility_box">
                    <FormControlLabel value="email_otp" control={<Radio onChange={() => setSelectedForm('email_otp')} />} label="Email Code" checked={selectedForm === 'email_otp'} />
                  </Grid>
                </Grid>
              )} */}

              {selectedForm === 'password' && (
                <FieldGroup
                  control={PatientLoginFormPassword}
                  render={({ get, invalid }) => (
                    <form>
                      <Stack className="form-fields">
                        <Grid container className="password_login_form" sx={{ display: 'flex', flexDirection: 'column' }} mb={2} spacing={2}>
                          <Grid item>
                            <FieldControl name="email" render={EmailInputIcon} meta={{ id: 'login_email', label: 'Email', placeholder: 'Please Enter Email', required: true, autocomplete: true, focused: true }} />
                          </Grid>
                          <Grid item>
                            <FieldControl name="password" render={PasswordInputIcon} meta={{ id: 'login_password', label: 'Password', placeholder: 'Please Enter Password', type: 'password', required: true, autocomplete: false, focused: true }} />
                          </Grid>
                        </Grid>
                        <Grid className="login-button-outer">
                          <Stack className="login-submit-btn">
                            <Box className="login-submit-btn-info">
                              <PrimaryButton label={'SUBMIT'} onClick={handlePasswordLogin} />
                            </Box>
                            <Grid container display="flex" justifyContent="space-between">
                              <Grid item lg={4} md={4} xs={12} alignItems="center">
                                <Box className="forgot-password-container">
                                  <Typography className="forgot-password-link-text" onClick={handleNavigateToForgotPassword}>
                                    Forgot Password
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                      </Stack>
                    </form>
                  )}
                />
              )}

              {selectedForm === 'sms_otp' && (
                <FieldGroup
                  control={PatientLoginFormSmsOtp}
                  render={({ get, invalid }) => (
                    <form autoComplete="false">
                      <Stack className="form-fields">
                        <Grid container className="otp_login_form" sx={{ display: 'flex', flexDirection: 'column' }} mb={2} spacing={2}>
                          <Grid item>
                            <FieldControl
                              name="phone"
                              render={InputPhone}
                              meta={{
                                name: 'phone',
                                value: 'phone',
                                helperText: 'Cell Phone Number is Required',
                                label: 'Cell Phone Number',
                                placeholder: 'Please Enter Cell Phone Number',
                                required: true
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid className="login-button-outer">
                          <Stack className="login-submit-btn">
                            <Box className="login-submit-btn-info">
                              <PrimaryButton label={'SUBMIT'} onClick={handleSendSmsOtp} />
                            </Box>
                          </Stack>
                        </Grid>
                      </Stack>
                    </form>
                  )}
                />
              )}

              {selectedForm === 'email_otp' && (
                <FieldGroup
                  control={PatientLoginFormEmailOtp}
                  render={() => (
                    <form autoComplete="false">
                      <Stack className="form-fields">
                        <Grid className="password_login_form">
                          <Grid mb={2}>
                            <FieldControl name="email" render={EmailInputIcon} meta={{ label: 'Email', placeholder: 'Please Enter Email', required: true }} />
                          </Grid>
                        </Grid>
                        <Grid className="login-button-outer">
                          <Stack className="login-submit-btn">
                            <Box className="login-submit-btn-info">
                              <PrimaryButton label={'SUBMIT'} onClick={handleSendEmailOtp} />
                            </Box>
                          </Stack>
                        </Grid>
                      </Stack>
                    </form>
                  )}
                />
              )}
            </Stack>

            <Stack className="bottom_shadow" direction="row" justifyContent="center">
              <img src={ShadowImage} alt="" width={700} />
            </Stack>
          </Stack>
          <ChatFloatingIcon />
        </Container>

        <Box className="left_top_img">
          <img src={TopImage} alt="" />
        </Box>
        <Box className="right_bottom_img">
          <img src={BottomImage} alt="" />
        </Box>
      </Box>
    </>
  );
};

export default LoginPage;
