import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  orders: [],
  unpaidOrders: [],
  shippedOrders: [],
  recentOrders: []
};

const Dashboard = createSlice({
  name: 'DashboardSlice',
  initialState,
  reducers: {
    setOrders(state: typeof initialState, action: PayloadAction<any>) {
      state.orders = action.payload.data;
    },
    setUnpaidOrders(state: typeof initialState, action: PayloadAction<any>) {
      state.unpaidOrders = action.payload.data;
    },
    setShippedOrders(state: typeof initialState, action: PayloadAction<any>) {
      state.shippedOrders = action.payload.data;
    },
    setRecentOrders(state: typeof initialState, action: PayloadAction<any>) {
      state.recentOrders = action.payload.data;
    }
  }
});
export default Dashboard;
