import Patient from '../../constants/grx-api';
import { PAY_INVOICE } from '../../constants/Endpoints';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { formatMessage } from '../../constants/util';

export const payOrderHandler = async (orderId: number | string) => {
  try {
    // pay invoice
    const payInvoiceRes: AxiosResponse = await Patient.post(`${PAY_INVOICE}/${orderId}`);
    if (payInvoiceRes?.status === 201) {
      toast(formatMessage('Payment Successfull'));
      return true;
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response?.data?.message));
      return;
    }
  }
};
