import { AppBar, Box, Container, Stack, Tab, Tabs, useTheme } from '@mui/material';
import { GET_SPECIALTIES, PRESCRIBER_PROFILE_GET } from '../../../constants/Endpoints';
import React, { useEffect, useState } from 'react';
import { TabPanel, tabProps } from '../../../components/tabs';
import { personalLoginForm, personalUserForm } from '../../../services/pages/settings/personalLoginForm';
import { physicianActions, settingsActions } from '../../../store/Actions';
import { useAppDispatch, useAppSelector } from '../../../store';

import { AxiosResponse } from 'axios';
import Chat from '../../../assets/icons/chat.svg';
import Header from '../../../components/header/header';
import PhysicianDoctor from '../../../constants/api';
import Doctor from '../../../constants/grx-api';
import Prescribe from '../../../assets/icons/prescribe_icon.svg';
import { Prescriber } from './childs/presicriber';
import { User } from '../../../models/User';
import UserInfo from './childs/user-info';
import { isLoggedIn } from '../../../services/auth/auth.service';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Footer from '../../../components/footer/footer';

export default function SettingPage() {
  const router = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [value, setValue] = React.useState(0);
  const userData: User = useAppSelector((state) => state.profileReducer.user);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };

  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    if (!isLoggedIn()) {
      router('/');
    } else {
      setIsLoaded(true);
    }
  }, [router]);

  return isLoaded ? (
    <>
      <Stack component="main" className="default-layout">
        <Header />
        <Box component="main" className="setting-page" position="relative">
          <Box className="main-content-wrapper-full">
            <Container maxWidth="xl">
              <Box className="main-content-wrap">
                <Box className="main-box settings_main_box">
                  <UserInfo />
                </Box>
              </Box>
            </Container>
          </Box>
          <Box className="chat-floating-icon">
            <img src={Chat} alt="logo" height={65} width={65} />
          </Box>
          {/* <Box className="Prescribe-icon" onClick={handlePrescribeDetail}>
            <img src={Prescribe} alt="logo" height={100} width={180} />
          </Box> */}
        </Box>
        <Box className="chat-floating-icon">
          <img src={Chat} alt="logo" height={65} width={65} />
        </Box>
        {/* <Box className="Prescribe-icon" onClick={handlePrescribeDetail}>
          <img src={Prescribe} alt="logo" height={100} width={180} />
        </Box> */}
      </Stack>
      <Footer />
    </>
  ) : (
    <div></div>
  );
}
