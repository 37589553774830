import React, { useState, useEffect } from 'react';
import { Box, Container, Stack, Typography, Grid, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import BottomImage from '../../../assets/icons/side-bottom.svg';
import LogoImage from '../../../assets/icons/logo.svg';
import PrimaryButton from '../../../core/buttons/primary-button';
import ShadowImage from '../../../assets/icons/login_shadow.svg';
import TopImage from '../../../assets/icons/side-top.svg';
import Avatar from '@mui/material/Avatar';
import ChatFloatingIcon from '../../../components/chat-floating-icon';
import EmailInputIcon from '../../../core/forms/inputs/EmailInputIcon';
import PasswordInputIcon from '../../../core/forms/inputs/PasswordInputIcon';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { PatientLoginMagicLinkHandler } from '../../../services/auth/login.service';
import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api';
import { GET_USER_DETAILS_BY_TOKEN } from '../../../constants/Endpoints';
import { toast } from 'react-toastify';
import { profileActions } from '../../../store/Actions';
import { User } from '../../../models/User';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';
import { formatMessage } from '../../../constants/util';

const EmailLinkLogin = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSendMagicLink = async () => {
    await PatientLoginMagicLinkHandler(navigate);
  };

  const handleEmailLogin = async (accessToken: string, idToken: string) => {
    try {
      const res: AxiosResponse = await PatientWithoutToken.get(GET_USER_DETAILS_BY_TOKEN, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });
      if (res.status === 200) {
        let data = res?.data;
        data['accessToken'] = accessToken;
        data['idToken'] = idToken;
        localStorage.clear();
        sessionStorage.clear();
        sessionStorage.setItem('isSessionOngoing', 'true');
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', '');
        localStorage.removeItem('tempUser');
        toast(formatMessage('Loggedin Successfully'));
        User.create(res?.data, true);
        dispatch(profileActions.setUserData({ user: data as User }));
        navigate('/dashboard');
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage('Link Expired'));
        navigate('/');
        return;
      }
    }
  };

  useEffect(() => {
    // Get the URL fragment
    console.log('check');
    const fragment = window?.location?.hash?.substring(1);
    // Get the access token
    const params = new URLSearchParams(fragment);
    const accessToken = params.get('access_token');
    const idToken = params.get('id_token');
    if (accessToken && idToken) {
      handleEmailLogin(accessToken, idToken);
    } else {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Box className="page email_page d-vh-center">
        <Container maxWidth="sm" className="login_out ">
          <Box className="forgot_main"></Box>
        </Container>
      </Box>
    </>
  );
});

export default EmailLinkLogin;
