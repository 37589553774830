export const notificationOptions = [
  { label: 'Email', value: 'Email' },
  { label: 'SMS', value: 'SMS' },
  { label: 'Both Email and SMS', value: 'Both' }
];
export const genderOptions = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' }
  // { label: 'Unknown ', value: 'Unknown' }
];

export const specialityOptions = [];

export const cityOptions = [
  { label: 'London', value: 'london' },
  { label: 'Delhi', value: 'delhi' },
  { label: 'Kolkata', value: 'kolkata' }
];
export const stateOptions = [
  { label: 'Punjab', value: 'punjab' },
  { label: 'Himachal', value: 'himachal' },
  { label: 'Jammu', value: 'jammu' }
];
