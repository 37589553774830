import { ASSET_BASE_URL, LOCAL_PROFILE_ENV } from '../constants/Endpoints';

import { Model } from './Model';

export class User extends Model {
  className = 'User';

  id = '';
  accessToken = '';
  allergies = [];
  idToken = '';
  refreshToken = '';
  firstName = '';
  middleName = '';
  lastName = '';
  gender = '';
  dateOfBirth = '';
  patientId = '';
  phoneNumber = {
    areaCode: '',
    number: '',
    countryCode: '',
    local: ''
  };
  email = '';
  notificationPreference = '';
  profilePicture = {
    FileType: '',
    Key: '',
    ACL: '',
    Location: '',
    Size: 0,
    Path: '',
    createdBy: '',
    createdByAccountType: '',
    createdAt: '',
    updatedAt: '',
    small: '',
    id: ''
  };
  patientRepresentative = {
    firstName: '',
    lastName: '',
    phoneNumber: {
      areaCode: '',
      countryCode: '',
      local: '',
      number: ''
    }
  };
  eyeDoctor = '';
  hasBillingAddress = '';
  hasShippingAddress = '';
  hasDefaultBillingCard = false;
  isEmailVerified = false;
  isPhoneNumberVerified = false;
  isExistingPatient = true;
  isBlueViewPatient = true;
  isAutoRefill = 0;

  get getImage() {
    if (!this?.profilePicture?.Location) {
      return LOCAL_PROFILE_ENV + 'assets/icons/dummy_user.png';
    } else {
      return this?.profilePicture?.Location;
    }
  }

  get getSmallImage() {
    if (this?.profilePicture?.small === undefined || this?.profilePicture?.small === '') {
      return LOCAL_PROFILE_ENV + 'assets/icons/dummy_user.png';
    } else {
      return this?.profilePicture?.small;
    }
  }
}
