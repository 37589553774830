import { AddNewCardFormHandler, addNewCardForm } from '../../../services/pages/popup-form/AddNewCardForm.service';
import { Box, Button, Container, DialogContent, DialogTitle, IconButton, Grid, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { DialogTitleProps } from '../../../interfaces/DialogTitleProps';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cityOptions, stateOptions } from '../../../services/components/selectOptions.service';
import OTPInput from 'react-otp-input';
import { useAppSelector, useAppDispatch } from '../../../store';
import PrimaryButton from '../../../core/buttons/primary-button';
import { sendEmailOtpHandler } from '../../../services/auth/verifyAccount.service';
import { User } from '../../../models/User';
import { toast } from 'react-toastify';
import { LINK_EXISTING_USER_EMAIL } from '../../../constants/Endpoints';
import Patient from '../../../constants/grx-api';
import { formatMessage } from '../../../constants/util';

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const renderInput = (inputProps: any, index: any) => {
  return <input {...inputProps} />;
};

export const LinkEmailAddress = (props: { handleClose: (e?: React.SyntheticEvent | boolean) => void; email: string }) => {
  const router = useNavigate();
  const dispatch = useAppDispatch();
  const user: User = useAppSelector((state) => state.profileReducer.user);
  const [otp, setOtp] = useState('');
  const [showTime, setShowTime] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const handleOtpChange = (otpVal: any) => {
    setOtp(otpVal);
  };

  const handleSubmit = async () => {
    try {
      if (otp.length < 4) {
        return toast(formatMessage('Please enter the four digit code sent to your email'));
      }

      const data = {
        email: user.email,
        code: otp
      };

      const res = await Patient.post(LINK_EXISTING_USER_EMAIL, data);
      if (res.status === 201) {
        setShowTime(false);
        setMinutes(0);
        setSeconds(0);
        props.handleClose(true);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast(formatMessage(error?.response?.data?.message));
        return false;
      }
    }
  };

  const resendOtp = async () => {
    try {
      const res = await sendEmailOtpHandler(props?.email);
      if (res) {
        setShowTime(true);
        setMinutes(1);
        setSeconds(30);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast(formatMessage(error?.response?.data?.message));
        setShowTime(false);
        setMinutes(0);
        setSeconds(0);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setShowTime(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    setOtp('');
    setShowTime(true);
    setMinutes(0);
    setSeconds(30);
  }, []);

  return (
    <>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={() => props.handleClose(false)}></BootstrapDialogTitle>
      <DialogContent dividers className="popup_content" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box className="confirm-otp-page d-vh-center" sx={{ backgroundColor: 'inherit' }}>
          <Container maxWidth="sm" className="confirm-otp-outer">
            <Stack direction="column" className="confirm-otp-card">
              <Stack direction="column" className="confirm-otp-form-box">
                <Stack className="title">Please enter the text code, you received on your email.</Stack>
                <Stack>
                  <form autoComplete="false">
                    <Stack className="form-fields">
                      <Box className="form-fields-input-btn">
                        <OTPInput
                          value={otp}
                          shouldAutoFocus={true}
                          inputStyle={{
                            width: '20%',
                            maxWidth: 90,
                            height: 56,
                            borderRadius: '12px',
                            border: '2px solid #00ACBA',
                            fontWeight: 'bold',
                            fontSize: 20
                          }}
                          numInputs={4}
                          onChange={handleOtpChange}
                          renderInput={renderInput}
                        />
                      </Box>
                      <Box className="confirm-otp-button-outer">
                        <Stack className="confirm-otp-SubmitBtn">
                          <Box className="confirm-otp-SubmitBtnInfo">
                            <PrimaryButton label={'SUBMIT'} onClick={handleSubmit} />
                          </Box>
                        </Stack>
                      </Box>
                      <Box sx={{ mt: 1 }}>
                        {showTime ? (
                          <Typography>
                            You can request for a new One Time Password in <b>{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</b>
                          </Typography>
                        ) : (
                          <Typography>
                            {' '}
                            Didn't receive the code? <b onClick={resendOtp}>Click here</b>
                          </Typography>
                        )}
                      </Box>
                    </Stack>
                  </form>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </Box>
      </DialogContent>
    </>
  );
};
