import { FormBuilder, Validators } from 'react-reactive-form';

import { AxiosResponse } from 'axios';
import { FORGOT_PASSWORD } from '../../constants/Endpoints';
import { NavigateFunction } from 'react-router-dom';
import { toast } from 'react-toastify';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { formatMessage } from '../../constants/util';

export const forgotPswForm = FormBuilder.group({
  email: ['', [Validators.required, Validators.email]]
});

export const forgotPasswordHandler = async (router?: NavigateFunction) => {
  try {
    forgotPswForm.controls.email.markAsTouched({ emitEvent: true, onlySelf: true });
    if (forgotPswForm.invalid) {
      return;
    }
    const data = forgotPswForm.value;
    const res: AxiosResponse = await PatientWithoutToken.post(`${FORGOT_PASSWORD}/${data?.email}`);
    if (res.status === 201) {
      toast(formatMessage('Kindly check your email to reset your password.'));
      if (router) {
        router('/forgot-password/done', { state: { data: data } });
      }
    }
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};
