import { Box, Container, Link, Typography } from '@mui/material';
import { Dispatch, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import Back from '../../../assets/icons/Arrow_back.svg';
import Bottom from '../../../assets/icons/side-bottom.svg';
import Chat from '../../../assets/icons/chat.svg';
import Email from '../../../assets/icons/email.svg';
import { FORGOT_PASSWORD } from '../../../constants/Endpoints';
import Top from '../../../assets/icons/side-top.svg';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';
import { formatMessage } from '../../../constants/util';

export default function Index() {
  const location = useLocation();

  const [email, setEmail] = useState<string | null>(location?.state?.data?.email);

  const router = useNavigate();
  useEffect(() => {
    if (email === null) {
      router('/forgot-password');
    }
  }, [email, router]);

  const openApp = () => {
    // if (email) {
    //   const linkArr = email.split('@');
    //   window.open('http://' + linkArr[1], '_blank');
    // }
  };

  const resend = async () => {
    try {
      const res: AxiosResponse = await PatientWithoutToken.post(`${FORGOT_PASSWORD}/${email}`);
      if (res.status === 201) {
        toast(formatMessage('Kindly check your email to reset your password.'));
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  console.log('Check');
  return (
    <Box className="page forgot_page email_page d-vh-center">
      <Container maxWidth="sm" className="login_out ">
        <Box className="forgot_main">
          <Box className="logo">
            <span className="logo_img">
              <img src={Email} alt="logo" height={30} width={30} />
            </span>
          </Box>
          <Typography component="h1">Check your email</Typography>
          <Typography component="p">
            We sent a password reset link to <br></br>
            {email}
          </Typography>

          <Box className="form_box">
            <Box className="click_option" sx={{ mt: 2 }}>
              <Typography component="p">
                Didn't receive the email? <span onClick={resend}> Click to resend</span>
              </Typography>
            </Box>
            <Link href="/">
              <Box className="back">
                <span>
                  <img src={Back} alt="logo" height={10} width={30} />
                </span>
                Back to log in
              </Box>
            </Link>
          </Box>
        </Box>
        <Box className="chat-floating-icon">
          <img src={Chat} alt="" height={65} width={65} />
        </Box>
      </Container>

      <Box className="left_top_img">
        <img src={Top} alt="" />
      </Box>
      <Box className="right_bottom_img">
        <img src={Bottom} alt="" />
      </Box>
    </Box>
  );
}
