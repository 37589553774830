import { Box, Container, Grid, Stack, Typography, Button, AppBar, Tab, Tabs, useTheme, Divider } from '@mui/material';
import { GET_PATIENT_ORDERS, GET_PATIENT_SHIPPING_ADDRESS, PATIENT_PROFILE_IMAGE_CHANGE, UPDATE_ORDER_SHIPPING_ADDRESS } from '../../../constants/Endpoints';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';

import { AxiosResponse } from 'axios';
import { BootstrapDialog } from '../../../core/tables/tableStyles';
import Carousel from 'react-material-ui-carousel';
import Chat from '../../../assets/icons/chat.svg';
import Patient from '../../../constants/grx-api';
import Header from '../../../components/header/header';
import Left from '../../../assets/icons/left_arrow.svg';
import { OrderTable } from '../../../components/tables/OrderTable';
import Right from '../../../assets/icons/right_arrow.svg';
import SecondaryButton from '../../../core/buttons/secondary-button';
import { User } from '../../../models/User';
import { dashboardActions } from '../../../store/Actions';
import { isLoggedIn } from '../../../services/auth/auth.service';
import { toast } from 'react-toastify';
import Footer from '../../../components/footer/footer';
import cameraPic from '../../../assets/icons/camera.svg';
import { profileActions } from '../../../store/Actions';
import { Stepper, Step } from 'react-form-stepper';
import noteImg from '../../../assets/icons/prescription.svg';
import veclImg from '../../../assets/icons/Layer_1.svg';
import delImg from '../../../assets/icons/mailbox.svg';
import Edit from '../../../assets/icons/deleat_edit.svg';
import pendingPayment from '../../../assets/icons/pending-payment.svg';
import preparing from '../../../assets/icons/preparing.svg';
import DoneIcon from '@mui/icons-material/Done';
import { Order } from '../../../models/Order';
import moment from 'moment';
import { AddNewCard } from '../../../components/popup/AddNewCard';
import { formatMessage } from '../../../constants/util';
import { addNewShippingAddressForm } from '../../../services/pages/popup-form/AddNewShippingAddressForm.service';
import { AddNewAddress } from '../../../components/popup/AddNewAddress';
import { EditShippingAddress } from '../../../components/popup/EditShippingAddress';
import { EditOrderShippingAddress } from '../../../components/popup/EditOrderShippingAddress';
import { UpdateOrderDeliveryDate } from '../../../components/popup/UpdateOrderDeliveryDate';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { RequestRefill } from '../../../components/popup/RequestRefill';
import {  AutoEnrollRefill } from '../../../components/popup/EnrollInAutoRefill';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};



const thousandsSaparator = (x: any) => {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

const OrderTabPanel = (props: any) => {
  const { children, value, index, order, handleEditAddress, getOrders, handleRequestRefill, handleEnrollAutoRefill, handleEditOrderAddress, handleUpdateOrderDeliveryDate, ...other } = props;
  const router = useNavigate();
  // console.log(props);

  // pay invoice
  const handleCurrentUnpaidInvoice = async (upaidOrder: Order) => {
    router('/home/prescriptions/order/' + upaidOrder?.order?.orderId, { state: { data: 'orders' } });
  };

  // track order
  const handleViewTracking = (order: Order) => {
    if (order?.order?.trackingUrl) {
      window.open(order?.order?.trackingUrl, '_blank');
    }
  };


  interface Prescription {
    refillLeft: number | string; // Adjust the type based on your actual data
    originalRefill: number;

  }



  // view prescription
  const handleViewPrescription = (order: Order) => {
    router('/home/prescriptions/order/' + order?.order?.orderId, { state: { data: 'orders' } });
  };

  const formattedExpectedDate = (order: Order) => {
    let expectedDate: any = null;
    if (order?.order?.paymentDate === null) {
      let dateAfterFourDays = dayjs.utc(new Date()).add(8, 'days');
      let orderExpectedDate = dayjs.utc(order.order.expectedByDate);
      if (orderExpectedDate.isAfter(dateAfterFourDays) || orderExpectedDate.isSame(dateAfterFourDays)) {
        expectedDate = orderExpectedDate;
      } else {
        expectedDate = dateAfterFourDays;
      }
    } else if (order?.order?.paymentDate && order?.order?.expectedByDate) {
      expectedDate = dayjs.utc(order?.order?.expectedByDate);
    }

    // const dateTime = dayjs.utc(dateValue);

    // Convert to the browser's local time
    const localDateTime = expectedDate.local();

    // Extract date components
    const date = localDateTime.format('Do');
    const month = localDateTime.format('MMM'); // Month name
    const year = localDateTime.format('YYYY');

    // Extract time
    // const time = localDateTime.format('hh:mm A');

    // return formattedDateTime;
    return `${month} ${date}, ${year}`;
  };

  const getStepperStatus = (status: string): number => {
    switch (status) {
      case 'Processing':
        return 0;
      case 'Pending Payment':
        return 1;
      case 'Preparing':
        return 2;
      case 'In Transit':
        return 4;
      default:
        return -1;
    }
  };

  // console.log("hiii", order)


  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
      {
        <>
          <Grid container item xs={12} md={12} xl={12} lg={12} sx={{ paddingTop: '30px', paddingBottom: '30px', mb: 2 }}>
            <Grid container item xs={12} md={12} xl={8} lg={8}>
              <Grid container className="order-processing-container" sm={12} xs={12} md={12} xl={12} lg={12} p={2}>
                <Grid item className="inside-container" sm={3} xs={3} md={3} xl={3} lg={3}>
                  <Typography className="processing-text">Processing</Typography>
                  <img src={noteImg} />
                </Grid>
                <Grid item className="inside-container" sm={3} xs={3} md={3} xl={3} lg={3}>
                  <Typography className="processing-text">Pending Payment</Typography>
                  <img src={pendingPayment} />
                </Grid>
                <Grid item className="inside-container" sm={3} xs={3} md={3} xl={3} lg={3}>
                  <Typography className="processing-text">Preparing</Typography>
                  <img src={preparing} />
                </Grid>
                <Grid item className="inside-container" sm={3} xs={3} md={3} xl={3} lg={3}>
                  <Typography className="processing-text">In Transit</Typography>
                  <img src={veclImg} />
                </Grid>
              </Grid>
              <Grid item className="stepper-container" sm={12} xs={12} md={12} xl={12} lg={12}>
                <Stepper
                  className="stepper-style"
                  activeStep={getStepperStatus(order?.order?.trackingStatus)}
                  connectorStyleConfig={{
                    size: 5,
                    disabledColor: '#00ACBA',
                    activeColor: '#00ACBA',
                    completedColor: '#00ACBA',
                    style: 'solid'
                  }}
                  hideConnectors={'inactive'}
                  styleConfig={{
                    activeBgColor: '#00ACBA',
                    activeTextColor: '#00ACBA',
                    completedBgColor: '#00ACBA',
                    completedTextColor: '#ffffff',
                    inactiveBgColor: '',
                    inactiveTextColor: '#fafafa',
                    size: 30,
                    circleFontSize: '',
                    labelFontSize: '',
                    borderRadius: '50%',
                    fontWeight: ''
                  }}
                >
                  <Step children={<DoneIcon style={{ fontSize: '15px' }} />} />
                  <Step children={<DoneIcon style={{ fontSize: '15px' }} />} />
                  <Step children={<DoneIcon style={{ fontSize: '15px' }} />} />
                  <Step children={<DoneIcon style={{ fontSize: '15px' }} />} />
                </Stepper>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={12} xl={3} lg={3} className="order-status-rightbox" spacing={1}>

              {order?.order?.isPayable ? (
                <>
                  <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                    <>
                      <Stack className="address-text-container">
                        <Typography className="address-heading"> Shipping Address: </Typography>
                        <Typography className="address-text" variant="body1">
                          <span className="address-row">{`${order?.shippingAddress?.address1 || ''} ${order?.shippingAddress?.address2 || ''}`.trim()}</span>
                          <br></br>
                          <span className="address-row">{`${order?.shippingAddress?.city || ''} ${order?.shippingAddress?.state || ''}`.trim()}</span>
                          <br></br>
                          <span className="address-row">{`${order?.shippingAddress?.zipCode || ''}`.trim()}</span>
                        </Typography>
                      </Stack>
                      {order?.order?.expectedByDate && (
                        <Stack className="delivery-date-container" direction={'row'}>
                          <Typography className="delivery-date-heading"> Expected By: </Typography> &nbsp;
                          <Typography className="delivery-date">{formattedExpectedDate(order)}</Typography>
                          {order.order.paymentDate === null && (
                            <span className="edit_icon" onClick={() => handleUpdateOrderDeliveryDate(order)}>
                              <img src={Edit} alt="Imprimis RX A Harrow Company" />
                            </span>
                          )}
                        </Stack>
                      )}
                      {order?.order?.canUpdateShippingAddress && (
                        <Button className="right-box-btn" onClick={() => handleEditOrderAddress(order)}>
                          Update Order Address
                        </Button>
                      )}
                    </>
                  </Grid>
                  <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                    <Button className="right-box-btn" onClick={() => handleCurrentUnpaidInvoice(order)}>
                      Pay Invoice
                    </Button>
                  </Grid>
                  {order?.order?.trackingNumber && order?.order?.trackingUrl && (
                    <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                      <Button className="right-box-btn" onClick={() => handleViewTracking(order)}>
                        View Tracking
                      </Button>
                    </Grid>
                  )}
                </>
              ) : (<>
                <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                  <>
                    <Stack className="address-text-container">
                      <Typography className="address-heading"> Shipping Address: </Typography>
                      <Typography className="address-text" variant="body1">
                        <span className="address-row">{`${order?.shippingAddress?.address1 || ''} ${order?.shippingAddress?.address2 || ''}`.trim()}</span>
                        <br></br>
                        <span className="address-row">{`${order?.shippingAddress?.city || ''} ${order?.shippingAddress?.state || ''}`.trim()}</span>
                        <br></br>
                        <span className="address-row">{`${order?.shippingAddress?.zipCode || ''}`.trim()}</span>
                      </Typography>
                    </Stack>
                    {order?.order?.expectedByDate && (
                      <Stack className="delivery-date-container" direction={'row'}>
                        <Typography className="delivery-date-heading"> Expected By: </Typography> &nbsp;
                        <Typography className="delivery-date">{formattedExpectedDate(order)}</Typography>
                        {order.order.paymentDate === null && (
                          <span className="edit_icon" onClick={() => handleUpdateOrderDeliveryDate(order)}>
                            <img src={Edit} alt="Imprimis RX A Harrow Company" />
                          </span>
                        )}
                      </Stack>
                    )}
                    {order?.order?.canUpdateShippingAddress && (
                      <Button className="right-box-btn" onClick={() => handleEditOrderAddress(order)}>
                        Update Order Address
                      </Button>
                    )}
                  </>

                </Grid>
                <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                  <Button className="right-box-btn" onClick={() => handleViewPrescription(order)}>
                    View Prescription
                  </Button>
                </Grid>
                {order?.order?.trackingNumber && order?.order?.trackingUrl && (
                  <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                    <Button className="right-box-btn" onClick={() => handleViewTracking(order)}>
                      View Tracking
                    </Button>
                  </Grid>
                )}

                {/* <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                  <Typography className="view-claim-form-link">View Claim Form</Typography>
                </Grid> */}

              </>
              )}
              {order?.prescriptions.some((items: Prescription) => items.originalRefill > 0)
                &&
                <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                  <Button className="right-box-btn btn-css" onClick={() => handleEnrollAutoRefill(order)}>
                  Auto Refill Management
                  </Button>
                </Grid>
              }
              {/* {order?.prescriptions[0].refillLeft > 0 && (
                <Grid item className="right-box-button-container" xs={10} md={10} xl={10} lg={10}>
                  <Button className="right-box-btn btn-css" onClick={() => handleRequestRefill(order)}>
                    Request Refill
                  </Button>
                </Grid>
              )} */}
            </Grid>
          </Grid>
          <Divider />
          {/* {order.prescriptions.map((prescription: any) => ( */}
          <Grid item container xl={12} lg={12} md={12} xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', overflowX: 'auto', minHeight: '200px', maxHeight: '270px', marginTop: 2, pt: 2 }}>
            {Array.isArray(order?.prescriptions) &&
              order?.prescriptions.map((prescription: any) => (
                <Grid item xl={6} lg={6} md={6} sm={6} xs={12} className="patient-medicine-container">
                  <Box className="medicine-image">
                    <img src={`https://www.imprimisrx.com/s/sfsites/c/cms/delivery/media/${prescription?.contentKey}`} />
                  </Box>
                  <Box className="medicine-desc">
                    {order?.order?.showPrice && <Typography className="medicine-price">${thousandsSaparator(prescription?.price.toFixed(2))}</Typography>}
                    <Typography className="medicine-name">{prescription?.name}</Typography>
                    <Typography className="prescriber-name">
                      Prescriber: <span>{`${order?.doctor?.firstName || ''} ${order?.doctor?.lastName || ''}`}</span>
                    </Typography>
                    <Typography className="issued-date">
                      Issued: <span>{moment.utc(order?.order?.date).format('MM-DD-YY')}</span>
                    </Typography>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </>
      }
    </div>
  );
};

const tabProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
};

const orderTabProps = (index: number) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
};




export default function DashboardPage() {
  const router = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [medication, setMedication] = useState<boolean>(false);
  const [model, setModel] = useState<{ billingPopup: boolean; editAddress: boolean; updateDeliveryDate: boolean; autoRefill: boolean }>({ billingPopup: false, autoRefill: false, editAddress: false, updateDeliveryDate: false });

  const unpaidOrders: Order[] = useAppSelector((state) => state.dashboardReducer.unpaidOrders);
  const orders: Order[] = useAppSelector((state) => state.dashboardReducer.orders);
  const recentOrders: Order[] = useAppSelector((state) => state.dashboardReducer.recentOrders);
  const [unpaidInvoiceTabIndex, setUnpaidInvoiceTabIndex] = useState<number>(0);
  const [shippedOrderTabIndex, setShippedOrderTabIndex] = useState<number>(0);
  const [orderId, setOrderId] = useState<string | number>(''); // to update shipping address and expected date
  const [orderExpectedDate, setOrderExpectedDate] = useState<Date | string | null>(null); // to update expected date

  // temp fix
  // const [shippedOrders, setShippedOrders] = useState<Order[]>(dummyOrders);
  const user: User = useAppSelector((state) => state.profileReducer.user);




  /* const handleEnrollAutoRefill = async (order: Order) => {
    //Check Auto Refill 
    const hasAutoRefill = order?.prescriptions.some((item) => parseInt((item as any).isAutoRefill as string) === 1);
    //Remaining AutoRefill Check
    const remainingRefiil = order?.prescriptions.some((items) => parseInt(items.refillLeft as string) < 1)

    if (remainingRefiil) {
      toast(formatMessage('You don&apos;t have any remaining refill left, please conatct customer service'));
    }
    else {
      if (!hasAutoRefill) {
        try {
          const res: AxiosResponse = await Patient.post(`/patient-portal/order/enroll-autorefill/${order?.order?.orderId}`);
          if (res.status === 201) {
            getOrders()
            //Toast for auto refill succesfully Submitted
            toast(formatMessage('Enroll in Auto Refill Successfully'));
            // router.back();
          }
          return res;
        } catch (err: any) {
          if (err?.response?.data?.message) {
            toast(formatMessage(err?.response.data.message));
            return;
          }
        }
      } else {
        //if already enroll
        toast(formatMessage("You are enrolled in auto refill, to cancel please connect with Customer service."));
      }
    }
  };
 */

  const handleEnrollAutoRefill = (order: Order) => {
    setOrderShippingAddress(order as Order);

    setModel(prevModel => ({
      ...prevModel,
      autoRefill: true
    }));
  };


  const handleRequestRefill = (order: Order) => {
    setOrderShippingAddress(order as Order);

    setModel(prevModel => ({
      ...prevModel,
      requestRefill: true
    }));
  };

  const handleRequestRefillClose = (res?: boolean) => {
    if (res) {
      getOrders();
    }
    closeModel();
  };
  const redirectToPrescriptions = () => {
    router('/home/prescriptions');
  };

  const handleProductTour = () => {
    window.open('https://prescribe.imprimisrx.com/home/dashboard?product_tour_id=443253', '_self');
  };

  const handleUnpaidInvoiceTabChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setUnpaidInvoiceTabIndex(newValue);
  };

  const handleOnOrderChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setShippedOrderTabIndex(newValue);
  };

  const closeModel = () => {
    setModel((prev) => ({ ...prev, billingPopup: false, editAddress: false, updateDeliveryDate: false, autoRefill: false, requestRefill: false }));
  };

  const handleBillingModelOpen = () => {
    const billingPopupFlag = parseInt(localStorage.getItem('billingPopup') as string);
    const hasDefaultBillingCard = user?.hasDefaultBillingCard;
    if (billingPopupFlag === 0) {
      if (!hasDefaultBillingCard) {
        setModel((prev) => ({ ...prev, billingPopup: !hasDefaultBillingCard }));
      }
    }
  };

  const handleBillingModelClose = (e?: string | React.SyntheticEvent) => {
    localStorage.setItem('billingPopup', '1');
    if (e === 'OK') {
      // As the user has cardsnow, so set the 'hasDefaultBillingCard' to true
      dispatch(profileActions.setUserData({ user: User.create({ ...user, hasDefaultBillingCard: true }, true) as User }));
    }
    closeModel();
  };

  const setOrderShippingAddress = (order: Order) => {
    addNewShippingAddressForm.patchValue({
      street: order.shippingAddress.address1,
      apt_suite: order.shippingAddress.address2,
      city: order.shippingAddress.city,
      state: order.shippingAddress.state,
      code: order.shippingAddress.zipCode,
      address: order.shippingAddress.address1
    });
    setOrderId(order?.order?.orderId);
  };

  const handleEditOrderAddress = (order: Order) => {
    setOrderShippingAddress(order as Order);
    setModel((oldData) => ({
      ...oldData,
      editAddress: true
    }));
  };

  const handleEditOrderAddressClose = (res?: boolean) => {
    if (res) {
      getOrders();
    }
    closeModel();
  };

  const handleUpdateOrderDeliveryDate = (order: Order) => {
    setOrderId(order?.order?.orderId);
    let expectedDate: any = null;
    if (order?.order?.paymentDate === null) {
      let dateAfterFourDays = dayjs.utc(new Date()).add(8, 'days');
      let orderExpectedDate = dayjs.utc(order.order.expectedByDate);
      if (orderExpectedDate.isAfter(dateAfterFourDays) || orderExpectedDate.isSame(dateAfterFourDays)) {
        expectedDate = orderExpectedDate.format('YYYY-MM-DD');
      } else {
        expectedDate = dateAfterFourDays.format('YYYY-MM-DD');
      }
    } else if (order?.order?.paymentDate && order?.order?.expectedByDate) {
      expectedDate = dayjs.utc(order?.order?.expectedByDate).format('YYYY-MM-DD');
    }
    setOrderExpectedDate(expectedDate);
    setModel((oldData) => ({
      ...oldData,
      updateDeliveryDate: true
    }));
  };

  const handleEnrollAutoRefillClose = (res?: boolean) => {
    if (res) {
      getOrders();
    }
    closeModel();
  };

  const handleUpdateOrderDeliveryDateClose = (res?: boolean) => {
    if (res) {
      getOrders();
    }
    closeModel();
  };

  // ------------ API CALLS -------------- //

  // orders
  const getOrders = async (offset = 0, count = 1000) => {
    try {
      const res: AxiosResponse = await Patient.get(GET_PATIENT_ORDERS, {
        params: { offset, count }
      });
      if (res.status === 200 && Array.isArray(res.data) && res.data.length > 0) {
        dispatch(dashboardActions.setOrders({ data: res.data }));
        // dispatch(dashboardActions.setShippedOrders({ data: res.data }));
        // dispatch(dashboardActions.setUnpaidOrders({ data: res.data })); // temp-fix
      }
    } catch (err: any) {
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };


  // image change
  // const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
  //   if (e?.target?.files?.length) {
  //     try {
  //       e.preventDefault();
  //       const formData = new FormData();
  //       formData.append('file', e.target.files[0]);
  //       const res: AxiosResponse = await Patient.post(PATIENT_PROFILE_IMAGE_CHANGE, formData, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'multipart/form-data'
  //         }
  //       });
  //       if (res.status === 201) {
  //         const newUser = { ...user, profilePicture: { ...user.profilePicture, ...res.data } };
  //         const duplicateUser: User = User.create({ ...newUser }, true) as User;
  //         dispatch(profileActions.setUserData({ user: duplicateUser as User }));
  //       }
  //     } catch (err: any) {
  //       if (err?.response?.data?.message) {
  //         toast(formatMessage(err?.response.data.message));
  //         return;
  //       }
  //     }
  //   }
  // };

  useEffect(() => {
    if (!isLoggedIn()) {
      router('/');
    } else {
      setIsLoaded(true);
    }
  }, [router]);

  useEffect(() => {
    if (user) {
      if (isLoggedIn() === true) {
        // onload api calls
        getOrders();
        handleBillingModelOpen();
      }
    }
  }, [dispatch, user]);

  return isLoaded && user ? (
    <>
      {model.billingPopup && (
        <BootstrapDialog open={model.billingPopup} PaperProps={{ style: { minHeight: '60%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <AddNewCard handleClose={handleBillingModelClose} skipButton={true} />
        </BootstrapDialog>
      )}
      {model.editAddress && (
        <BootstrapDialog onClose={() => handleEditOrderAddressClose()} open={model.editAddress} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <EditOrderShippingAddress handleClose={(res?: boolean) => handleEditOrderAddressClose(res)} orderId={Number(orderId)} />
        </BootstrapDialog>
      )}
      {model.updateDeliveryDate && (
        <BootstrapDialog onClose={() => handleUpdateOrderDeliveryDateClose()} open={model.updateDeliveryDate} PaperProps={{ style: { height: 'auto', minHeight: 500, width: 360, overflowX: 'auto' } }}>
          <UpdateOrderDeliveryDate handleClose={(res?: boolean) => handleUpdateOrderDeliveryDateClose(res)} orderId={Number(orderId)} orderDate={orderExpectedDate as Date} />
        </BootstrapDialog>
      )}
       {model.autoRefill && (
        <BootstrapDialog onClose={() => handleEnrollAutoRefillClose()} open={model.autoRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <AutoEnrollRefill handleClose={(res?: boolean) => handleEnrollAutoRefillClose(res)} orderId={Number(orderId)} />
        </BootstrapDialog>
      )} 
{/*       {model.requestRefill && (
        <BootstrapDialog onClose={() => handleRequestRefillClose()} open={model.requestRefill} PaperProps={{ style: { minHeight: '30%', maxHeight: '89%', minWidth: ' 40%', maxWidth: 650 } }}>
          <RequestRefill handleClose={(res?: boolean) => handleRequestRefillClose(res)} orderId={Number(orderId)} />
        </BootstrapDialog>
      )} */}
      <Stack component="main" className="default-layout">
        <Header />
        <Box className="dashboard-page" position="relative">
          <Container maxWidth="xl">
            <Stack className="main-content-wrap">
              {/* <Stack id="dashboard_set" direction={{ xs: 'column', sm: 'column', lg: 'row', xl: 'row' }} display="none">
                <Box flex={{ xs: 1 }} className="dashboard_col_layout">
                  <Stack direction={{ xs: 'column', md: 'row' }} className="dashboardFlex">
                    <Box padding={1}>
                      <Box className="card-sample-block">
                        <Stack className="patient_profile" direction={{ sm: 'column', lg: 'row' }} alignItems="center">
                          <Box>
                            <Box className="card-user-profile" style={user.getSmallImage ? { background: `url('${user?.getSmallImage}` } : {}}>
                              <label className="dashboard_camera" htmlFor="upload-button">
                                <img src={cameraPic} alt="camera" width={38} height={38} />
                              </label>
                              <input type="file" id="upload-button" style={{ display: 'none' }} onChange={handleImageChange} />
                            </Box>
                          </Box>
                          <Box className="card-user-desc">
                            <Typography className="heading-03-v1  heading-03-v1-size" variant="h3" component="h3">
                              Welcome, <br /> {`${user.firstName} ${user.middleName} ${user.lastName}`.trim()}
                            </Typography>
                            <Typography component="p" className="paragraph-03-v2">
                              <Typography className="small" style={{ marginTop: '7px', wordBreak: 'keep-all' }}>
                                Here’s what’s happening in your account!
                              </Typography>
                            </Typography>
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                    <Box padding={1} flex={{ xs: 1, md: 3 }} sx={{ overflowX: 'hidden' }}>
                      <Box className="card-sample-block">
                        <Box className="card_sample_in">
                          <Box className="patient-invoice-block">
                            <Box className="patient-invoice-heading">
                              <Box className="patient-invoice-heading-text">
                                <Typography variant="h4" component="h4" className="heading-04-v1" color="#263238CC">
                                  Unpaid Invoices
                                </Typography>
                              </Box>
                            </Box>
                            <Box className="patient-unpaid-container">
                              {unpaidOrders && unpaidOrders.length > 0 ? (
                                <Box className="top_tab_out signup_header">
                                  <AppBar position="static" className="tabs-bar">
                                    <Tabs
                                      className="unpaid_invoices_head"
                                      value={unpaidInvoiceTabIndex}
                                      onChange={handleUnpaidInvoiceTabChange}
                                      TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
                                      style={{ background: '#ffffff' }}
                                      indicatorColor="primary"
                                      textColor="inherit"
                                      variant="fullWidth"
                                    >
                                      {unpaidOrders &&
                                        unpaidOrders.map((item: Order, index: number) => {
                                          return <Tab className={unpaidInvoiceTabIndex === index ? 'tab-btn' : 'tab-btn-nonactive'} key={index} label={item?.order?.invoiceNumber} {...tabProps(index)} />;
                                        })}
                                    </Tabs>
                                    {unpaidOrders.map((item: Order, index: number) => (
                                      <>
                                        <TabPanel key={index} value={unpaidInvoiceTabIndex} index={index}>
                                          <Grid container xl={12} lg={12} md={12} xs={12} style={{ display: 'flex', flexDirection: 'column', overflowX: 'scroll', minHeight: '200px', maxHeight: '250px' }}>
                                            {Array.isArray(item?.prescriptions) &&
                                              item?.prescriptions.map((prescription) => (
                                                <Grid item xl={4} lg={4} md={4} xs={12} className="patient-medicine-container">
                                                  <Box className="medicine-image">
                                                    <img src="https://www.imprimisrx.com/s/sfsites/c/cms/delivery/media/MCSATDSFIJUBGT7OX7HPRPWKNACA" height={100} width={100} />
                                                  </Box>
                                                  <Box className="medicine-desc">
                                                    {item?.order?.showPrice && <Typography className="medicine-price">${prescription?.price}</Typography>}
                                                    <Typography className="medicine-name">{prescription?.name}</Typography>
                                                    <Typography className="prescriber-name">
                                                      Prescriber: <span>{`${item?.doctor?.firstName} ${item?.doctor?.lastName}`}</span>
                                                    </Typography>
                                                    <Typography className="issued-date">
                                                      Issued: <span>{moment.utc(item?.order?.date).format('MM-DD-YY')}</span>
                                                    </Typography>
                                                  </Box>
                                                </Grid>
                                              ))}
                                          </Grid>
                                          {item?.order?.isPayable && item?.order?.showPrice && (
                                            <Box className="add_btn_out" sx={{ mt: 2 }}>
                                              <Button className="add_btn" onClick={() => handleCurrentUnpaidInvoice(item)}>
                                                Pay Invoice
                                              </Button>
                                            </Box>
                                          )}
                                        </TabPanel>
                                      </>
                                    ))}
                                  </AppBar>
                                </Box>
                              ) : (
                                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
                                  <Typography className="no-invoice-message">No invoice available for payment</Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Box>
              </Stack> */}
              <Box padding={1}>
                <Box className="card-sample-block">
                  <Box className="card_sample_in">
                    <Box className="patient-order-block">
                      <Box className="patient-order-heading">
                        <Box className="patient-order-heading-text">
                          <Typography variant="h4" component="h4" className="heading-04-v1" color="#263238CC">
                            {/* Order Status */}
                            Pending Prescriptions
                          </Typography>
                        </Box>
                      </Box>
                      <Grid container xs={12} md={11} lg={11} sx={{ margin: 'auto', mt: 3 }} className="patient-unpaid-container">
                        {orders && orders.length > 0 ? (
                          <Grid xs={12} md={12} lg={12} sm={12} xl={12} className="order-status-container">
                            <AppBar position="static" className="tabs-bar">
                              <Grid item className="order-status-tabs-container" xs={12} md={12} lg={12} sm={12} xl={12}>
                                <Tabs value={shippedOrderTabIndex} onChange={handleOnOrderChange} style={{ background: '#ffffff' }} indicatorColor="primary" textColor="inherit" variant="scrollable" scrollButtons="auto">
                                  {orders && orders.map((order: Order, index: number) => <Tab className="tab-btn" style={{ flex: '1 1 30%' }} label={`Order #${order?.order?.invoiceNumber}`} {...orderTabProps(index)} />)}
                                </Tabs>
                              </Grid>
                              <Grid item xs={12} md={12} lg={12} sm={12} xl={12} className="order-status-tab">
                                {orders &&
                                  orders.map((order: Order, index: number) => (
                                    <OrderTabPanel value={shippedOrderTabIndex} index={index} order={order} dir={theme.direction} getOrders={getOrders} handleRequestRefill={handleRequestRefill} handleEditOrderAddress={handleEditOrderAddress} handleEnrollAutoRefill={handleEnrollAutoRefill} handleUpdateOrderDeliveryDate={handleUpdateOrderDeliveryDate} />
                                  ))}
                              </Grid>
                            </AppBar>
                          </Grid>
                        ) : (
                          <Box sx={{ display: 'flex', justifyContent: 'center', height: '50vh', width: '100%' }}>
                            <Typography className="no-orders-message">No Pending Prescriptions</Typography>
                          </Box>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Box padding={1}>
                <Box className="card-sample-block">
                  <Box style={{ display: 'flex', paddingTop: '10px', alignItems: 'flex-end' }}>
                    <Box style={{ flex: 1 }}>
                      <Typography variant="h4" textAlign="center" component="h4" className="heading-04-v1" color="#263238CC">
                        Most Recent Orders
                      </Typography>
                    </Box>
                    <Box>
                      <Stack onClick={redirectToPrescriptions} style={{ cursor: 'pointer' }}>
                        <Box className="view_all_info">View All</Box>
                      </Stack>
                    </Box>
                  </Box>
                  <Box className="recent-order-table-layout">
                    {user ? (
                      orders && <OrderTable data={orders} />
                    ) : (
                      <Box className="most_recent_prescription_container">
                        <Stack mt={2} pt={4} pb={4} textAlign="center">
                          <Typography className="important_message">
                            <b>Important:</b> Here you will be able to view and sort your most recent orders{' '}
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box> */}
            </Stack>
          </Container>
        </Box>

        <Box className="chat-floating-icon">
          <img src={Chat} alt="logo" height={50} width={50} />
        </Box>
        {/* <Box className="Prescribe-icon" onClick={handlePrescribeDetail}>
          <img src={Prescribe} alt="logo" height={100} width={180} />
        </Box> */}
      </Stack>
      <Footer />
    </>
  ) : (
    <></>
  );
}
