import { FormBuilder, Validators } from 'react-reactive-form';

import { AxiosResponse } from 'axios';
import { SEND_SMS_OTP, SEND_EMAIL_OTP } from '../../constants/Endpoints';
import { toast } from 'react-toastify';
import PatientWithoutToken from '../../constants/grx-api-tokenless';
import { formatMessage } from '../../constants/util';

export const sendSmsOtpHandler = async (areaCode: string, number: string) => {
  try {
    const data = {
      areaCode,
      number
    };
    const res: AxiosResponse = await PatientWithoutToken.post(SEND_SMS_OTP, data);
    if (res.status === 201) {
      toast(formatMessage('A verification code has been sent to your phone number'));
    }
    return true;
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return false;
    }
  }
};

export const sendEmailOtpHandler = async (email: string) => {
  try {
    const res = await PatientWithoutToken.post(`${SEND_EMAIL_OTP}/${email}`);
    if (res.status === 201) {
      toast(formatMessage('A verification code has been sent to your email'));
    }
    return true;
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return false;
    }
  }
};
