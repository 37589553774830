import { FormBuilder, Validators } from 'react-reactive-form';

import { ADD_NEW_SHIPPING_ADDRESS, UPDATE_SHIPPING_ADDRESS, UPDATE_ORDER_SHIPPING_ADDRESS } from '../../../constants/Endpoints';
import { AxiosResponse } from 'axios';
import Patient from '../../../constants/grx-api';
import { NavigateFunction } from 'react-router-dom';
import React from 'react';
import { toast } from 'react-toastify';
import { formatMessage } from '../../../constants/util';

export const addNewShippingAddressForm = FormBuilder.group({
  street: ['', [Validators.required]],
  apt_suite: ['', []],
  city: ['', [Validators.required]],
  state: ['', [Validators.required]],
  code: ['', [Validators.required]]
  // address: ['', []]
});


interface ShippingAddress {
  address1: any;
  address2: any;
  city: any;
  state: any;
  zipCode: any;
}

export const AddNewShippingAddressFormHandler = async (event: React.SyntheticEvent, router: NavigateFunction) => {
  try {
    event.preventDefault();
    addNewShippingAddressForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.code.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.address.markAsTouched({ emitEvent: true, onlySelf: true });

    if (addNewShippingAddressForm.invalid) {
      return;
    }
    let data = {
      street: addNewShippingAddressForm.value.street,
      apt_suite: addNewShippingAddressForm.value.apt_suite,
      city: addNewShippingAddressForm.value.city,
      state: addNewShippingAddressForm.value.state,
      postal_code: addNewShippingAddressForm.value.code,
      address: addNewShippingAddressForm.value.address
    };
    const res: AxiosResponse = await Patient.post(ADD_NEW_SHIPPING_ADDRESS, data);

    if (res.data.status === 'OK') {
      toast(formatMessage('Add New Prescriber Registered Successfully'));
      // router.back();
    }
  } catch (err: any) {
    if (err?.response?.data?.status === 'Error') {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

export const EditShippingAddressFormHandler = async () => {
  try {
    addNewShippingAddressForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.code.markAsTouched({ emitEvent: true, onlySelf: true });
    // addNewShippingAddressForm.controls.address.markAsTouched({ emitEvent: true, onlySelf: true });

    if (addNewShippingAddressForm.invalid) {
      return;
    }
    let data = {
      city: addNewShippingAddressForm?.value?.city?.trim() || '',
      state: addNewShippingAddressForm?.value?.state?.trim() || '',
      zipCode: addNewShippingAddressForm?.value?.code?.trim() || '',
      address1: addNewShippingAddressForm?.value?.street?.trim() || '',
      address2: addNewShippingAddressForm?.value?.apt_suite?.trim() || '',
      country: 'US'
    };

    // return null;
    const res: AxiosResponse = await Patient.post(UPDATE_SHIPPING_ADDRESS, data);

    if (res.status === 201) {
      toast(formatMessage('Shipping Address Updated Successfully'));
      // router.back();
    }
    return res;
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

export const EditOrderShippingAddressFormHandler = async (orderId: number) => {
  try {
    addNewShippingAddressForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.code.markAsTouched({ emitEvent: true, onlySelf: true });
    // addNewShippingAddressForm.controls.address.markAsTouched({ emitEvent: true, onlySelf: true });

    if (addNewShippingAddressForm.invalid) {
      return;
    }

    let data = {
      city: addNewShippingAddressForm?.value?.city?.trim() || '',
      state: addNewShippingAddressForm?.value?.state?.trim() || '',
      zipCode: addNewShippingAddressForm?.value?.code?.trim() || '',
      address1: addNewShippingAddressForm?.value?.street?.trim() || '',
      address2: addNewShippingAddressForm?.value?.apt_suite?.trim() || '',
      country: 'US'
    };

    // return null;
    const res: AxiosResponse = await Patient.post(`${UPDATE_ORDER_SHIPPING_ADDRESS}/${orderId}`, data);

    if (res.status === 201) {
      toast(formatMessage('Shipping Address Updated Successfully'));
      // router.back();
    }
    return res;
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};

const normalizeString = (str: any) => typeof str === 'string' ? str.trim().replace(/\s+/g, ' ') : str;

export const UpdateRefillOrderShippingAddressFormHandler = async (orderId: number, originalShippingAddress: ShippingAddress) => {
  try {
    addNewShippingAddressForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.code.markAsTouched({ emitEvent: true, onlySelf: true });
    // addNewShippingAddressForm.controls.address.markAsTouched({ emitEvent: true, onlySelf: true });

    if (addNewShippingAddressForm.invalid) {
      return;
    }

    let data = {
      city: addNewShippingAddressForm?.value?.city?.trim() || '',
      state: addNewShippingAddressForm?.value?.state?.trim() || '',
      zipCode: addNewShippingAddressForm?.value?.code?.trim() || '',
      address1: addNewShippingAddressForm?.value?.street?.trim() || '',
      address2: addNewShippingAddressForm?.value?.apt_suite?.trim() || '',
      country: 'US'
    };

    const updatedShippingAddress = {
      country: 'US',
      ...originalShippingAddress,
    };
    const normalizedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, normalizeString(value)])
    );

    const normalizedOriginal = Object.fromEntries(
      Object.entries(updatedShippingAddress).map(([key, value]) => [key, normalizeString(value)])
    );

    // Check for updates
    const isUpdated = Object.keys(normalizedData).some(
      key => normalizedData[key as keyof ShippingAddress] !== normalizedOriginal[key as keyof ShippingAddress]
    );


    // return null;
    if (isUpdated) {
      const res: AxiosResponse = await Patient.post(`${UPDATE_ORDER_SHIPPING_ADDRESS}/${orderId}`, {
        ...data,
       });

      if (res.status === 201) {
        // toast(formatMessage('Shipping Address Updated Successfully'));
        // router.back(); // Uncomment if you want to navigate back
      }

      return res;
    } 
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};


export const EditRefillOrderShippingAddressFormHandler = async (rxId: Array<number>, selectedCardInfo: Object) => {

  try {
    addNewShippingAddressForm.controls.street.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.apt_suite.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.city.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.state.markAsTouched({ emitEvent: true, onlySelf: true });
    addNewShippingAddressForm.controls.code.markAsTouched({ emitEvent: true, onlySelf: true });
    // addNewShippingAddressForm.controls.address.markAsTouched({ emitEvent: true, onlySelf: true });

    if (addNewShippingAddressForm.invalid) {
      return;
    }


    let data = {
      rxId: rxId,
      card: selectedCardInfo
    };

    // return null;
    const res: AxiosResponse = await Patient.post(`/patient-portal/order/request-refills/`, data);

    if (res.status === 201) {
      // toast(formatMessage('Shipping Address Updated Successfully'));
      // router.back();
    }
    return res;
  } catch (err: any) {
    if (err?.response?.data?.message) {
      toast(formatMessage(err?.response.data.message));
      return;
    }
  }
};