import { AppBar, Box, Container, Stack, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { TabPanel, tabProps } from '../../../components/tabs';

import ChatFloatingIcon from '../../../components/chat-floating-icon';
import Logo from '../../../assets/icons/logo.svg';
import PatientFormOne from '../../../pages/signup/patient-form-one';
import React, { useEffect } from 'react';
// import StaffForm from "../../../pages/signup/staff-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { PatientSignUpOneForm } from '../../../services/auth/register.service';
import callUsIcon from '../../../assets/icons/mobile.svg';

export default function SignUpPage() {
  const router = useNavigate();
  const location = useLocation();

  const gotoLogin = () => {
    router('/');
  };

  // loiad the form data if found in the local storage
  const loadFormData = () => {
    PatientSignUpOneForm.reset();
    if (localStorage.getItem('registrationForm1')) {
      let data = JSON.parse(localStorage.getItem('registrationForm1') as string);
      PatientSignUpOneForm.patchValue({
        firstName: data?.firstName,
        middleName: data?.middleName,
        lastName: data?.lastName,
        gender: data?.gender,
        dateOfBirth: new Date(data?.dateOfBirth),
        phone: (data?.areaCode + data?.phone).replace(/\D/g, ''),
        email: data?.email,
        password: data?.password,
        notification_preference: data?.notification_preference,
        isAllergies: data?.isAllergies,
        allergies: data?.allergies,
        representativeFirstName: data?.representativeFirstName,
        representativeLastName: data?.representativeLastName,
        representativeContact: data?.representativeContact,
        eyeDoctor: data?.eyeDoctor,
        // automaticRefill: data?.automaticRefill,
        updateConsent: data?.updateConsent,
        promotionConsent: data?.promotionConsent,
        shippingZipCode: data?.shippingZipCode
      });
    } else {
      PatientSignUpOneForm.patchValue({ isAllergies: 'true' });
    }
  };

  useEffect(() => {
    loadFormData();
  }, []);

  return (
    <Stack component="main" className="default-layout signup-page">
      <Container maxWidth="xl">
        <Box className="head" sx={{ mb: 3 }}>
          <Box className="logo">
            <img src={Logo} alt="" width={150} onClick={gotoLogin} />
          </Box>
          <a href="tel:8582642082" className="call-us-link">
            <Stack direction="row" alignItems="center">
              <img src={callUsIcon} alt="Imprimis RX A Harrow Company" width={20} />
              <Typography>{`(858) 264-2082`}</Typography>
            </Stack>
            <Typography className="call-us-text">Text us with any questions</Typography>
          </a>
        </Box>
      </Container>

      <Container maxWidth="lg">
        <Box className="main-box">
          <Box sx={{ bgcolor: 'background.paper' }}>
            <PatientFormOne />
          </Box>
        </Box>
      </Container>

      <ChatFloatingIcon />
    </Stack>
  );
}
