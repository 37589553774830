import { FormBuilder, Validators } from 'react-reactive-form';

import { AxiosResponse } from 'axios';
import PhysicianDoctor from '../../constants/api';
import { RESET_PASSWORD } from '../../constants/Endpoints';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { formatMessage } from '../../constants/util';

export default class RestPassword {
  changePasswordForm = FormBuilder.group({
    token: ['', [Validators.required]],
    password: ['', [Validators.required]],
    confirm_password: ['', [Validators.required]]
  });

  resetPasswordHandler = async () => {
    const router = useNavigate();
    try {
      this.changePasswordForm.controls.password.markAsTouched({ emitEvent: true, onlySelf: true });
      this.changePasswordForm.controls.confirm_password.markAsTouched({ emitEvent: true, onlySelf: true });

      if (this.changePasswordForm.invalid) {
        return;
      }
      const data = this.changePasswordForm.value;

      const res: AxiosResponse = await PhysicianDoctor.post(RESET_PASSWORD, data);

      if (res.data.status === 'OK') {
        toast(formatMessage('Reset Password Successfully.'));
        router('/');
      }
    } catch (err: any) {
      if (err?.response?.data?.status === 'Error') {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };
}
