import React, { useState, useEffect } from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import BottomImage from '../../../assets/icons/side-bottom.svg';
import LogoImage from '../../../assets/icons/logo.svg';
import PrimaryButton from '../../../core/buttons/primary-button';
import ShadowImage from '../../../assets/icons/login_shadow.svg';
import TopImage from '../../../assets/icons/side-top.svg';
import Avatar from '@mui/material/Avatar';
import ChatFloatingIcon from '../../../components/chat-floating-icon';
import OTPInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { profileActions } from '../../../store/Actions';
import { toast } from 'react-toastify';
import Patient from '../../../constants/grx-api';
import { LINK_SMS_ACCOUNT, SEND_SMS_OTP } from '../../../constants/Endpoints';
import { User } from '../../../models/User';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';
import { sendSmsOtpHandler } from '../../../services/auth/verifyAccount.service';
import { formatMessage } from '../../../constants/util';

const VerifyPhone = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user: User = useAppSelector((state) => state.profileReducer.user);
  const [otp, setOtp] = useState('');
  const [showTime, setShowTime] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const renderInput = (inputProps: any, index: any) => {
    return <input {...inputProps} />;
  };

  const handleOtpChange = (otpVal: any) => {
    setOtp(otpVal);
  };

  const handleSubmit = async () => {
    try {
      if (otp.length < 4) {
        return toast(formatMessage('Please enter the four digit code sent to your phone'));
      }

      const data = {
        phoneNumber: {
          areaCode: user?.phoneNumber?.areaCode,
          number: user?.phoneNumber?.number
        },
        code: otp
      };

      const res = await PatientWithoutToken.post(LINK_SMS_ACCOUNT, data);
      if (res.status === 201) {
        setShowTime(false);
        setMinutes(0);
        setSeconds(0);
        navigate('/verify-email');
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast(formatMessage(error?.response?.data?.message));
      }
    }
  };

  const resendOtp = async () => {
    try {
      const res = await sendSmsOtpHandler(user?.phoneNumber?.areaCode, user.phoneNumber.number);
      if (res) {
        setShowTime(true);
        setMinutes(1);
        setSeconds(30);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast(formatMessage(error?.response?.data?.message));
        setShowTime(false);
        setMinutes(0);
        setSeconds(0);
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setShowTime(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    setOtp('');
    setShowTime(true);
    setMinutes(0);
    setSeconds(30);
  }, []);

  return (
    <>
      <Box className="page confirm-otp-page d-vh-center">
        <Container maxWidth={false} className="confirm-otp-outer">
          <Stack direction="column" className="confirm-otp-card">
            <Box className="logo-box d-vh-center">
              <img src={LogoImage} alt="logo" width={320} />
            </Box>
            <Stack direction="column" className="confirm-otp-form-box">
              <Stack className="title">Please enter the code you received on your phone.</Stack>
              <Stack>
                <form autoComplete="false">
                  <Stack className="form-fields">
                    <Box className="form-fields-input-btn">
                      <OTPInput
                        value={otp}
                        shouldAutoFocus={true}
                        inputStyle={{
                          width: 97,
                          height: 56,
                          borderRadius: '12px',
                          border: '2px solid #00ACBA',
                          fontWeight: 'bold',
                          fontSize: 20
                        }}
                        numInputs={4}
                        onChange={handleOtpChange}
                        renderInput={renderInput}
                      />
                    </Box>
                    <Box className="confirm-otp-button-outer">
                      <Stack className="confirm-otp-SubmitBtn">
                        <Box className="confirm-otp-SubmitBtnInfo">
                          <PrimaryButton label={'SUBMIT'} onClick={handleSubmit} />
                        </Box>
                      </Stack>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      {showTime ? (
                        <Typography>
                          You can request a new code in: <b>{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</b>
                        </Typography>
                      ) : (
                        <Typography>
                          {' '}
                          Didn't receive the code? <b onClick={resendOtp}>Click here</b>
                        </Typography>
                      )}
                    </Box>
                  </Stack>
                </form>
              </Stack>
            </Stack>
            <Stack className="bottom_shadow" direction="row" justifyContent="center">
              <img src={ShadowImage} alt="" width={700} />
            </Stack>
          </Stack>
          <ChatFloatingIcon />
        </Container>

        <Box className="left_top_img">
          <img src={TopImage} alt="" />
        </Box>
        <Box className="right_bottom_img">
          <img src={BottomImage} alt="" />
        </Box>
      </Box>
    </>
  );
};

export default VerifyPhone;
