import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Button, Container, DialogContent, FormControl, Grid, Stack, TextField, Typography } from '@mui/material';
import { FieldControl, FieldGroup } from 'react-reactive-form';
import { InputSearchSelect } from '../../../core/forms/inputs/InputSearchSelect';
import { AxiosResponse } from 'axios';
import { GET_PATIENTS_BY_NAME } from '../../../constants/Endpoints';
import { toast } from 'react-toastify';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';
import { CustomerSupportLogin, CustomerSupportLoginHandler } from '../../../services/auth/login.service';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { profileActions } from '../../../store/Actions';
import { User } from '../../../models/User';
import moment from 'moment';
import axios from 'axios';
import PrimaryButton from '../../../core/buttons/primary-button';
import TertiaryButton from '../../../core/buttons/tertiary-button';
import { formatMessage } from '../../../constants/util';

const ArrowIcon = () => (
  <svg width="38" height="18" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <svg width="28" height="18" x="10" y="13" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.6664 1.17334C11.6005 1.38801 11.4512 1.53965 11.3007 1.69341C9.64847 3.37876 7.99715 5.06532 6.3479 6.75402C6.14555 6.96138 5.91548 7.0582 5.63862 6.96351C5.5352 6.92819 5.43328 6.85815 5.3555 6.77868C3.64009 5.03152 1.92737 3.28193 0.215843 1.53051C-0.0717466 1.23637 -0.0705537 0.855458 0.211075 0.558581C0.343098 0.41943 0.474525 0.278451 0.618766 0.153002C0.861951 -0.0583138 1.19901 -0.0476556 1.4419 0.165487C1.47915 0.198371 1.51432 0.233996 1.54918 0.269621C2.94243 1.69311 4.33597 3.11629 5.72862 4.54038C5.7608 4.57327 5.78703 4.61194 5.8669 4.67375C5.88865 4.63264 5.90266 4.58362 5.93365 4.55195C7.33912 3.11355 8.74577 1.67636 10.153 0.239782C10.4156 -0.0281683 10.7774 -0.0817585 11.0229 0.138691C11.2733 0.363404 11.5484 0.575024 11.6667 0.916965V1.17304L11.6664 1.17334Z"
        fill="#00ACBA"
      />
    </svg>
  </svg>
);

export const LoginCustomerSupport = (props: { handleClose: (e: React.SyntheticEvent) => void }) => {
  const [search, setSearch] = useState<string>('');
  const [time, setTime] = useState(moment());
  const [patientList, setPatientList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getPatientByName = async (name: string) => {
    try {
      const { REACT_APP_GRX_BASE_URL } = process.env;
      setLoading(true);
      const result: AxiosResponse = await axios.get(`${REACT_APP_GRX_BASE_URL}${GET_PATIENTS_BY_NAME}/${name}`);
      if (result?.status === 200) {
        setLoading(false);
        setPatientList(() => result.data.map((item: any) => ({ value: item?.id, label: item?.name })));
      }
    } catch (err: any) {
      setLoading(false);
      if (err?.response?.data?.message) {
        toast(formatMessage(err?.response.data.message));
        return;
      }
    }
  };

  const onSelectPatient = (data: any) => {
    if (data) {
      let tempUser: any = localStorage.getItem('tempUser');
      tempUser = tempUser ? JSON.parse(tempUser) : {};
      CustomerSupportLogin.patchValue({
        email: tempUser?.email,
        password: tempUser?.password,
        patientId: data?.value
      });
      setPatientList([]);
    }
  };

  const handleSubmit = async () => {
    const data = await CustomerSupportLoginHandler(navigate);
    if (data) {
      dispatch(profileActions.setUserData({ user: data as User }));
    }
  };

  const searchDebounce = (searchFunction: () => void, timeInterval: number) => {
    if (moment().diff(moment(time)) > timeInterval) {
      searchFunction();
      setTime(moment());
    }
  };

  useEffect(() => {
    if (search) {
      searchDebounce(() => getPatientByName(search), 2000);
    }
  }, [search]);

  return (
    <>
      <DialogContent dividers className="popup_content" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box component="main" className="card-info" sx={{ width: '100%' }}>
          <Container maxWidth="lg">
            <Box className="main-box">
              <Box sx={{ bgcolor: 'background.paper' }}>
                <Stack className="modal_heading_main" direction="row" justifyContent="center" alignItems="center">
                  <Typography className="heading_bottom_without_border">Login As Customer Support</Typography>
                </Stack>
                {/* <FieldGroup
                  control={CustomerSupportLogin}
                  render={({ get, invalid }) => ( */}
                <form>
                  <Grid container spacing={2} display="flex" justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} lg={10}>
                      <FormControl className="form-input" sx={{ width: '100%' }}>
                        <Autocomplete
                          options={patientList}
                          loading={loading}
                          getOptionLabel={(option: any) => option.label}
                          popupIcon={<ArrowIcon />}
                          onChange={(event: React.SyntheticEvent, newValue: unknown) => {
                            onSelectPatient(newValue);
                          }}
                          renderInput={(params: any) => <TextField {...params} label={'Select Patient'} placeholder={'Please Select Patient'} onChange={(e) => setSearch(e.target.value)} />}
                        />
                      </FormControl>
                    </Grid>

                    <Grid container item xs={12} sm={12} md={12} lg={10} spacing={2} display="flex" justifyContent="space-around">
                      <Grid item xs={6} sm={6} md={4} lg={6}>
                        <TertiaryButton label={'Cancel'} onClick={props.handleClose} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={6}>
                        <PrimaryButton label={'Login'} onClick={handleSubmit} />
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
                {/* )}
                /> */}
              </Box>
            </Box>
          </Container>
        </Box>
      </DialogContent>
    </>
  );
};
