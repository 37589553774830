import React, { useState, useEffect } from 'react';
import { Box, Container, Stack, Typography, Grid } from '@mui/material';
import BottomImage from '../../../assets/icons/side-bottom.svg';
import LogoImage from '../../../assets/icons/logo.svg';
import PrimaryButton from '../../../core/buttons/primary-button';
import ShadowImage from '../../../assets/icons/login_shadow.svg';
import TopImage from '../../../assets/icons/side-top.svg';
import BackArrow from '../../../assets/icons/Arrow_back.svg';
import Avatar from '@mui/material/Avatar';
import ChatFloatingIcon from '../../../components/chat-floating-icon';
import OTPInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store';
import { profileActions } from '../../../store/Actions';
import { toast } from 'react-toastify';
import Patient from '../../../constants/grx-api';
import { SEND_SMS_OTP, SEND_EMAIL_OTP } from '../../../constants/Endpoints';
import { User } from '../../../models/User';
import { PatientLoginSmsOtpHandler, PatientLoginEmailOtpHandler } from '../../../services/auth/login.service';
import PatientWithoutToken from '../../../constants/grx-api-tokenless';
import { formatMessage } from '../../../constants/util';

const ConfirmOtp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user: User = useAppSelector((state) => state.profileReducer.user);
  const [loginMode, setLoginMode] = useState(location?.state?.loginMode);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<{ areaCode: string; number: string }>({
    areaCode: '',
    number: ''
  });
  const [otp, setOtp] = useState('');
  const [showTime, setShowTime] = useState(false);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  const handleBackToLogin = () => {
    navigate('/');
  };

  const renderInput = (inputProps: any, index: any) => {
    return <input {...inputProps} />;
  };

  const handleOtpChange = (otpVal: any) => {
    setOtp(otpVal);
  };

  const handleSubmit = async () => {
    try {
      if (otp.length < 4) {
        return toast(formatMessage(`Please enter the four digit code sent to your ${loginMode}`));
      }

      let data: any = {};
      if (loginMode === 'email') {
        data = await PatientLoginEmailOtpHandler(otp);
      }

      if (loginMode === 'phone') {
        data = await PatientLoginSmsOtpHandler(otp);
      }

      if (Object.keys(data).length) {
        localStorage.clear();
        setShowTime(false);
        setMinutes(0);
        setSeconds(0);
        sessionStorage.setItem('isSessionOngoing', 'true');
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('idToken', data?.idToken);
        localStorage.setItem('accessToken', data?.accessToken);
        localStorage.setItem('refreshToken', data?.refreshToken);
        toast(formatMessage('Loggedin Successfully'));
        dispatch(profileActions.setUserData({ user: User.create(data, true) as User }));
        navigate('/');
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast(formatMessage(error?.response?.data?.message));
      }
    }
  };

  const resendSmsOtp = async () => {
    try {
      const data = {
        areaCode: phone.areaCode,
        number: phone.number
      };

      const res = await PatientWithoutToken.post(SEND_SMS_OTP, data);

      if (res.status === 201) {
        setOtp('');
        setShowTime(true);
        setMinutes(1);
        setSeconds(30);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast(formatMessage(error?.response?.data?.message));
        setShowTime(false);
        setMinutes(0);
        setSeconds(0);
      }
    }
  };

  const resendEmailOtp = async () => {
    try {
      const email = localStorage.getItem('email');

      const res = await PatientWithoutToken.post(`${SEND_EMAIL_OTP}/${email}`);

      if (res.status === 201) {
        setOtp('');
        setShowTime(true);
        setMinutes(1);
        setSeconds(30);
      }
    } catch (error: any) {
      if (error?.response?.data?.message) {
        toast(formatMessage(error?.response?.data?.message));
        setShowTime(false);
        setMinutes(0);
        setSeconds(0);
      }
    }
  };

  const resendOtp = async () => {
    if (loginMode === 'email') {
      resendEmailOtp();
    }

    if (loginMode === 'phone') {
      resendSmsOtp();
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setShowTime(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    if (!loginMode) {
      navigate('/');
    } else if (loginMode === 'phone') {
      const areaCode = localStorage.getItem('areaCode') || '';
      const number = localStorage.getItem('number') || '';
      if (areaCode && number) {
        setPhone((prev) => ({
          areaCode: areaCode,
          number: number
        }));
      } else {
        navigate('/');
      }
    } else if (loginMode === 'email') {
      const tempEmail = localStorage.getItem('email') || '';
      if (tempEmail) {
        setEmail((prev) => tempEmail);
      } else {
        navigate('/');
      }
    }
  }, []);

  const formatPhoneNumber = (phoneNumber: string) => {
    const formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    return formattedNumber;
  };

  useEffect(() => {
    setOtp('');
    setShowTime(true);
    setMinutes(0);
    setSeconds(30);
  }, []);

  return (
    <>
      <Box className="page confirm-otp-page">
        <Container className="confirm-otp-outer" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Grid container direction="column" sm={12} md={8} lg={6} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item className="logo-box d-vh-center" mb={2}>
              <img src={LogoImage} alt="logo" width={320} />
            </Grid>
            <Grid container item direction="column" className="confirm-otp-form-box" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9 }}>
              <Grid item className="back_link">
                <Typography onClick={handleBackToLogin}>
                  <span>
                    <img src={BackArrow} alt="Imprimis RX A Harrow Company" />
                  </span>
                  Back to Login screen
                </Typography>
              </Grid>
              {loginMode === 'email' && <Grid item className="title">{`Please enter the code you received on email id '${email}'.`}</Grid>}
              {loginMode === 'phone' && <Grid item className="title">{`Please enter the code you received on your phone number '${formatPhoneNumber(`${phone.areaCode}${phone.number}`)}'.`}</Grid>}
              <Grid item sx={{ m: 'auto', p: 0 }}>
                <form autoComplete="false">
                  <Grid className="form-fields">
                    <Box className="form-fields-input-btn">
                      <OTPInput
                        value={otp}
                        shouldAutoFocus={true}
                        inputStyle={{
                          width: '20%',
                          maxWidth: 90,
                          height: 56,
                          borderRadius: '12px',
                          border: '2px solid #00ACBA',
                          fontWeight: 'bold',
                          fontSize: 20
                        }}
                        numInputs={4}
                        onChange={handleOtpChange}
                        renderInput={renderInput}
                      />
                    </Box>
                    <Box className="confirm-otp-button-outer">
                      <Stack className="confirm-otp-SubmitBtn">
                        <Box className="confirm-otp-SubmitBtnInfo">
                          <PrimaryButton label={'SUBMIT'} onClick={handleSubmit} />
                        </Box>
                      </Stack>
                    </Box>
                    <Box sx={{ mt: 1, cursor: showTime ? 'auto' : 'pointer' }}>
                      {showTime ? (
                        <Typography>
                          You can request a new code in: <b>{`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}</b>
                        </Typography>
                      ) : (
                        <Typography>
                          {' '}
                          Didn't receive the code? <b onClick={resendOtp}>Click here</b>
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                </form>
              </Grid>
            </Grid>
            <Grid className="bottom_shadow" direction="row" justifyContent="center">
              <img src={ShadowImage} alt="" width={700} />
            </Grid>
          </Grid>
          <ChatFloatingIcon />
        </Container>

        <Box className="left_top_img">
          <img src={TopImage} alt="" />
        </Box>
        <Box className="right_bottom_img">
          <img src={BottomImage} alt="" />
        </Box>
      </Box>
    </>
  );
};

export default ConfirmOtp;
