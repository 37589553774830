import { Button, ButtonProps, styled } from '@mui/material';

const ColorButton = styled(Button)<ButtonProps & { customStyles?: React.CSSProperties }>(({ theme, customStyles }) => ({
  color: '#00ACBA',
  fontSize: 17,
  fontWeight: 600,
  backgroundColor: 'transparent',
  border: '2px solid #00ACBA',
  borderRadius: '8px',
  boxShadow: 'none',
  minWidth: 100,
  '&:hover': {
    backgroundColor: '#00ACBA',
    color: '#ffffff',
    boxShadow: 'none'
  },
  '&.MuiButton-root': {
    padding: 10,
    ...customStyles
  },
  '&.Mui-disabled': {
    color: '#d8d8d8',
    border: '2px solid #d8d8d8',
    backgroundColor: '#f2f2f2',
    boxShadow: 'none',
    cursor: 'default'
  }
}));

const SecondaryButton = (props: { onClick?: (e?: React.SyntheticEvent) => void; label?: string; disabled?: boolean | undefined; customStyles?: React.CSSProperties }) => {
  return (
    <ColorButton onClick={props.onClick} className="primary-button" variant="contained" disabled={props.disabled} sx={{ width: '100%' }} customStyles={props.customStyles}>
      {props?.label}
    </ColorButton>
  );
};

export default SecondaryButton;
