import { FormArray, FormControl as FormControlType, FormGroup } from 'react-reactive-form';
import { FormControl, TextField } from '@mui/material';

enum Color {
  PRIMARY = 'primary',
  ERROR = 'error',
  SECONDARY = 'secondary',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning'
}

export const InputText = ({ handler, touched, hasError, meta }: FormArray | FormControlType | FormGroup) => {
  const inputElem = handler();
  const id = meta.id;
  let helperText = meta.helperText;

  const getColor = () => {
    if (touched) {
      if (hasError('required')) {
        return Color.ERROR;
      }
      return Color.SUCCESS;
    }
    return Color.PRIMARY;
  };

  const getError = () => {
    if (touched) {
      if (hasError('required')) {
        helperText = meta.helperText;
        return true;
      }
      if (hasError('pattern')) {
        helperText = meta.patternErrorMessage || `Special characters not allowed in the ${meta.label}`;
        return true;
      }
      helperText = meta.helperText;
      return false;
    }
    if (typeof meta.required != 'undefined' && meta.required === false) {
      return false;
    }
    return false;
  };

  const isRequired = () => {
    if (typeof meta.required != 'undefined') {
      return meta.required;
    }

    return true;
  };

  return (
    <FormControl className="form-input" sx={{ width: '100%' }}>
      <TextField
        id={id}
        required={isRequired()}
        label={meta.label}
        InputProps={{
          disabled: meta?.disabled ? meta?.disabled : false,
          autoComplete: meta?.autoComplete ? 'on' : 'new-password'
        }}
        fullWidth
        color={getColor()}
        helperText={getError() && helperText}
        error={getError()}
        {...inputElem}
        autoComplete={meta?.autoComplete ? 'on' : 'new-password'}
        disabled={meta.disabled ? meta.disabled : false}
      />
    </FormControl>
  );
};
